import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useStaticQuery, graphql } from 'gatsby'
import qs from 'qs';
import navigate from '../utils/navigate'
import Header from '../components/Header';
import Footer from '../components/Footer';
import random from '../utils/random';

export default function ViewLeaveRequest({ location }) {
  const data = useStaticQuery(graphql`
    query ViewLeaveRequestPage {
      site {
        siteMetadata {
          home
          pathPrefix
          viewLeaveRequestURL
        }
      }
    }
  `)
  const { home, pathPrefix, viewLeaveRequestURL } = data.site.siteMetadata

  const [state, setState] = useState({
    Loading: false,
    GUID: '',
  })
  const values = qs.parse(location.search)
  const GUID = values['?LeaveRequestID']

  useEffect(() => {
    // viewleaverequest.html?LeaveRequestID=667708a0-1562-414a-a9a4-eb7beeaeaf94
    // ViewLeaveRequest.html?LeaveRequestID=e87f52d0-fcf3-4b15-b2db-eda733805fe3
    if (!GUID) navigate(home);

    setState(prev => ({ ...prev, GUID }))

    // Example of using a variable inside of the HTML file to use inside of a react application
    // if(window.ViewLeaveRequestURL)
    //   ViewLeaveRequestURL = window.ViewLeaveRequestURL
    axios
      .get(`${viewLeaveRequestURL + GUID}&V=${random()}`)
      .then((res) => {
        setState(prev => ({ ...prev, Loading: false, ...res.data }))
      })
      .catch(() => {
        navigate(home);
      });
  }, [GUID, home, viewLeaveRequestURL])

  if (state.Contact) {
    const {
      Contact, LeaveRequest, Manager, Member,
    } = state
    return (
      <div>
        <div id="pagewrapper" className="pgwrapper-shdw">
          <Header NoLogout />
          <div id="nav">
            <h3 className="white" style={{ padding: '.6em 1em' }}>Open Leave Request# {state.GUID}</h3>
          </div>
          <div id="content-bg" className="contentbg-gradient">
            <div className="clear">
              <br />
            </div>
            <div className="content-outline-998 viewRequest">
              {/* <table width="99%" border="0" cellpadding="0" cellspacing="1" style={{fontSize: "1.5em"}}>
                <tr>
                  <td align="left"><strong>Leave Status: </strong><strong className="Yellowbold">Pending</strong></td>
                  <td align="right"><strong>Leave Type:</strong> FMLA</td>
                  </tr>
              </table> */}
              <table width="99%" border="0" cellPadding="0" cellSpacing="1">
                <tr>
                  <td colSpan="4" className="Table_Heading">Personal Information</td>
                </tr>
                <tr>
                  <td width="15%" align="right" className="cellgray1"><strong>First Name:</strong></td>
                  <td width="17%" className="cellgray1"><input disabled type="text" name="fname" id="fname" value={Member.FirstName} style={{ width: '8.75em', height: '1.3em' }} className="formfield" /></td>
                  <td width="14%" align="right" className="cellgray1"><strong>Last Name:</strong></td>
                  <td width="18%" className="cellgray1"><input disabled type="text" name="lname" id="lname" value={Member.LastName} style={{ width: '8.75em', height: '1.3em' }} className="formfield" /></td>
                </tr>
                <tr className="cellgray2">
                  <td align="right"><strong>Date of Birth:</strong></td>
                  <td><input disabled type="text" name="DOB" id="DOB" style={{ width: '8.75em', height: '1.3em' }} className="formfield" value={Member.DOB} /></td>
                  <td align="right"><strong> SSN:</strong></td>
                  <td>
                    <span className="cellgray1">
                      <input disabled type="text" name="SSN" id="SSN" style={{ width: '6.5em', height: '1.3em' }} className="formfield" value={Member.SSN} />
                    </span>
                  </td>
                </tr>
                <tr className="cellgray1">
                  <td align="right"><strong> Date of Hire:</strong></td>
                  <td><input disabled type="text" name="address" id="address" style={{ width: '8.75em', height: '1.3em' }} className="formfield" value={Member.DOH} /></td>
                  <td align="right"><strong>Employee ID #</strong></td>
                  <td><input disabled type="text" name="city" id="city" style={{ width: '8.75em', height: '1.3em' }} className="formfield" value={Member.EmployeeNumber} /></td>
                </tr>
                <tr className="cellgray2">
                  <td align="right"><strong>Street Address:</strong></td>
                  <td><input disabled type="text" name="address2" id="address4" style={{ width: '8.75em', height: '1.3em' }} className="formfield" value={Member.StreetAddress} /></td>
                  <td align="right"><strong>City:</strong></td>
                  <td><input disabled type="text" name="EmpID" id="EmpID" style={{ width: '8.75em', height: '1.3em' }} className="formfield" value={Member.CityAddress} /></td>
                </tr>
                <tr className="cellgray1">
                  <td align="right"><strong>State:</strong></td>
                  <td>
                    <select className="formfield" style={{ height: '1.75em' }} name="state" value={Member.StateID}>
                      {Member.StateList.filter(state => parseInt(state.Value, 10) === Member.StateID).map(state => <option value={state.Value}>{state.Name} </option>)}
                    </select>
                  </td>
                  <td align="right"><strong>Zip:</strong></td>
                  <td><input disabled type="text" name="zip5" id="zip5" style={{ width: '4.35em', height: '1.3em' }} className="formfield" value={Member.ZipAddress} /></td>
                </tr>
              </table>
              <p>&nbsp;</p>
              <p><br />
              </p>
              <table width="100%" border="0" cellPadding="0" cellSpacing="1">
                <tr>
                  <td colSpan="4" className="Table_Heading">          Supervisor Information</td>
                </tr>
                <tr>
                  <td width="17%" align="right" className="cellgray1"><strong> Supervisor Name:</strong></td>
                  <td width="30%" className="cellgray1"><input disabled type="text" name="supername" id="supername" style={{ width: '8.75em', height: '1.3em' }} className="formfield" value={Manager.FullName} /></td>
                  <td width="17%" align="right" className="cellgray1"><strong>Title:</strong></td>
                  <td width="36%" className="cellgray1"><input disabled type="text" name="supertitle" id="lname2" style={{ width: '8.75em', height: '1.3em' }} className="formfield" value={Manager.Title} /></td>
                </tr>
                <tr className="cellgray2">
                  <td align="right"><strong>Email Address:</strong></td>
                  <td><input disabled type="text" name="semail" id="semail" style={{ width: '12.5em', height: '1.3em' }} className="formfield" value={Manager.EmailAddress} /></td>
                  <td align="right"><strong>Phone:</strong></td>
                  <td>
                    <span className="cellgray1">
                      <input disabled type="text" name="sphone" id="sphone" style={{ width: '8.75em', height: '1.3em' }} className="formfield" value={Manager.Phone} />
                    </span>
                  </td>
                </tr>
                <tr className="cellgray1">
                  <td colSpan="3" align="right"><strong>Have you notified your manager of your intention to request a leave of absense ?</strong></td>
                  <td>
                    <input disabled type="radio" name="snotifiedyes" id="snotifiedyes" value="snotifiedyes" checked={Manager.IsNotifiedManager} />
                    Yes
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input disabled type="radio" name="snotifiedno" id="snotifiedno" value="snotifiedno" checked={!Manager.IsNotifiedManager} />
                    No
                  </td>
                </tr>
              </table>
              <p><br />
              </p>
              <table width="100%" border="0" cellSpacing="1" cellPadding="0">
                <tr>
                  <td colSpan="4" className="Table_Heading">Contact Information</td>
                </tr>
                <tr>
                  <td width="25%" align="right" className="cellgray1"><strong>Preferred Phone: </strong></td>
                  <td width="25%" className="cellgray1"><input disabled type="text" name="prefphone" id="prefphone" style={{ width: '8.75em', height: '1.3em' }} className="formfield" value={Contact.PreferedPhone === 'Other' ? Contact.OtherPhone : Contact.PreferedPhone} /></td>
                  <td width="26%" align="right" className="cellgray1"><strong>Can be sent text messages?</strong></td>
                  <td width="24%" className="cellgray1">
                    <input disabled type="radio" name="txtmsgYes" id="txtmsgYes" value="txtmsgYes" checked={Contact.IsAcceptTextMessage} />
                    Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input disabled type="radio" name="txtmsgNo" id="txtmsgNo" value="txtmsgNo" checked={!Contact.IsAcceptTextMessage} />
                    No
                  </td>
                </tr>
                <tr>
                  <td align="right" className="cellgray2"> <strong>Preferred Email Address:</strong></td>
                  <td colSpan="3" className="cellgray2"><input disabled type="text" name="prefemail" id="Prefemail" style={{ width: '12.5em', height: '1.3em' }} className="formfield" value={Contact.AcceptEmail === 'Other' ? Contact.OtherEmail : Contact.AcceptEmail} /></td>
                </tr>
                <tr>
                  <td colSpan="2" align="right" className="cellgray1"><strong>I prefer that you send all of my communications via</strong>:</td>
                  <td colSpan="2" className="cellgray1">{Contact.PreferCommunicationTypeList ? Contact.PreferCommunicationTypeList.filter(type => parseInt(type.Value, 10) === Contact.PreferCommunicationTypeID)[0].Name : 'Error'}</td>
                </tr>
              </table>
              <br />
              <table width="100%" border="0" cellSpacing="1" cellPadding="0">
                <tr>
                  <td colSpan="4" bgcolor="#A7000F" className="Table_Heading">Leave of Absence Information</td>
                </tr>
                <tr>
                  <td align="right" className="cellgray2"><strong>Last Day Worked:</strong></td>
                  <td width="19%" className="cellgray2"><input disabled name="Lastdworked" type="text" className="formfield" id="zip2" style={{ width: '6em', height: '1.3em' }} value={LeaveRequest.LastDayOfWork} /></td>
                  <td width="16%" align="right" className="cellgray2"><strong>Dates of Leave:</strong></td>
                  <td width="46%" className="cellgray2">From&nbsp;
                    <input disabled name="zip3" type="text" className="formfield" id="zip3" style={{ width: '6em', height: '1.3em' }} value={LeaveRequest.LeaveStart} />
                    &nbsp;&nbsp;&nbsp;&nbsp; To&nbsp;
                    <input disabled name="zip4" type="text" className="formfield" id="zip4" style={{ width: '6em', height: '1.3em' }} value={LeaveRequest.LeaveEnd} />
                  </td>
                </tr>
                <tr>
                  <td width="19%" align="right" className="cellgray1"><strong>Reason for Leave Request:</strong></td>
                  <td colSpan="3" className="cellgray1">
                    <select name="reason" id="reason" value={LeaveRequest.LeaveReasonID}>
                      {LeaveRequest.LeaveReasonList.map(reason => <option value={reason.Value}>{reason.Name}</option>)}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="cellgray2">Is this Leave Related to your own Pregnancy? &nbsp;&nbsp;
                    <input type="radio" name="radio" id="IsPregnancyYes" value="IsPregnancyYes" checked={LeaveRequest.IsPregnancyRelated} />
                    Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio" name="radio" id="isPregnancyNo" value="isPregnancyNo" checked={!LeaveRequest.IsPregnancyRelated} />
                    No
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="cellgray1">What is your Expected Due Date?&nbsp;&nbsp;
                    <span className="cellgray2">
                      <input name="PregDueDate" type="text" className="formfield" id="zip" style={{ width: '6em', height: '1.3em' }} value={LeaveRequest.ExpectedDueDate} />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="cellgray1">What is your Childs DOB?&nbsp;&nbsp;
                    <span className="cellgray2">
                      <input name="PregDueDate" type="text" className="formfield" id="zip" style={{ width: '6em', height: '1.3em' }} value={LeaveRequest.ChildDOB} />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="cellgray2">If you have selected to take leave because of someone other than yourself please specify your family relationship: &nbsp;&nbsp;
                    <input disabled type="text" name="relationship" id="relationship" style={{ width: '8.75em', height: '1.3em' }} className="formfield" value={LeaveRequest.FamilyRelationship} />
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="cellgray1">Leave explanation:<br />
                    <textarea disabled name="conditiondetails" id="conditiondetails" style={{ width: '37.5em', height: '6.25em' }}>{LeaveRequest.LeaveExplanation}</textarea>
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="cellgray2">Is work related health condition? &nbsp;&nbsp;
                    <input disabled type="radio" name="prefnum9yes" id="prefnum91" value="prefnum9yes" checked={LeaveRequest.IsWorkRelatedHealthCondition} />
                    Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input disabled type="radio" name="prefnum10no" id="prefnum101" value="prefnum10no" checked={!LeaveRequest.IsWorkRelatedHealthCondition} />
                    No
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="cellgray2">Did you file a Workers Compensation Claim? &nbsp;&nbsp;
                    <input disabled type="radio" name="WorkersCompClaimYes" id="WorkersCompClaimYes" value="WorkersCompClaimYes" checked={LeaveRequest.IsFiledWorkerCompensationClaim} />
                    Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input disabled type="radio" name="WorkersCompClaimNo" id="WorkersCompClaimNo" value="WorkersCompClaimNo" checked={!LeaveRequest.IsFiledWorkerCompensationClaim} />
                    No
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="cellgray1"> If work related can you please give us an explaination:<br />
                    <textarea disabled name="workconditiondetails" id="workconditiondetails" style={{ width: 600, height: 100 }}>{LeaveRequest.WorkRelatedExplanation}</textarea>
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="cellgray2"><strong>Is this an intermittent or reduced schedule leave?&nbsp;&nbsp;</strong>
                    <input disabled type="radio" name="prefnum5yes" id="prefnum51" value="prefnum5yes" checked={LeaveRequest.IsPeriodic_Intermittent_ReducedScheduledLeave} />
                    Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input disabled type="radio" name="prefnum6no" id="prefnum61" value="prefnum6no" checked={!LeaveRequest.IsPeriodic_Intermittent_ReducedScheduledLeave} />
                    No
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="cellgray2">If &quot;yes&quot; please explain:<br />
                    <textarea disabled name="reducedleave_details" id="reducedleave_details" style={{ width: '37.5em', height: '6.25em' }}>{LeaveRequest.Periodic_Intermittent_ReducedScheduledLeave_Explanation}</textarea>
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="cellgray1"><strong>Do you want to use additional vacation accrual beyond what is required by company policy?</strong> &nbsp;&nbsp;
                    <input disabled type="radio" name="prefnum7yes" id="prefnum7" value="prefnum7yes" checked={LeaveRequest.IsPaidVacation} />
                    Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input disabled type="radio" name="prefnum8no" id="prefnum8" value="prefnum8no" checked={!LeaveRequest.IsPaidVacation} />
                    No
                  </td>
                </tr>
              </table>
              <table width="100%" border="0" cellSpacing="1" cellPadding="0">
                <tbody>
                  <tr>
                    <td colSpan="3" className="table-heading-color1"> Please acknowledge the following statements: </td>
                  </tr>
                  {LeaveRequest.StatementsList.map(statement => (
                    <tr key={statement.Value}>
                      <td width="5%" align="center" className="table-graybg2">
                        <span className="Acknowledgement">
                          <input
                            disabled
                            id={`Statement_Value_${statement.Value}`}
                            type="checkbox"
                            name={`Statement_Value_${statement.Value}`}
                            checked
                          />
                        </span>
                      </td>
                      <td colSpan="2" className="table-graybg1 alignleft">
                        <p>
                          {statement.Name}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p className="clear">&nbsp;</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  return (
    <div>
      <div id="pagewrapper" className="pgwrapper-shdw">
        <Header NoLogout />
        <div id="nav">
          <h3 className="white" style={{ padding: '.6em 1em' }}>Open Leave Request# {state.GUID}</h3>
        </div>
        <div id="content-bg" className="contentbg-gradient">
          <div className="content-outline-998 viewRequest" style={{ textAlign: 'center' }}>
            <strong style={{ fontSize: '1.5em' }}>Loading Leave Request...</strong><br />
            <img
              src={`${pathPrefix}/styles/images/LoadingAnim.gif`}
              width="30%"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
